html.overlay,
html.overlay body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
}

body {
    font-family: 'Roboto', sans-serif;
    max-width: 965px;
    margin: 20px;
}

.page-content h1 {
    font-weight: bold;
    font-size: 32px;
    color: #524779;
    letter-spacing: 0;
}

.page-content div {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 25px;
}

.election-manifestos {
    list-style: none;
    margin: 0;
    padding: 0;
}

.election-manifestos li {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
}

.election-manifestos li h4 {
    display: none;
}

.election-manifestos li img {
    flex: 0 0 100px;
    margin-right: 20px;
    max-width: 70px;
    max-height: 40px;
}

.election-manifestos li a {
    flex: 1 1 auto;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
}

.btn-party-select,
.btn-theme-select {
    display: block;
    padding: 15px;
    width: 100%;
    background-color: #EFEAFF;
    border: 0;
    border-radius: 4px;
    text-align: left;
    margin-bottom: 15px;

    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 24px;
}

.btn-party-select {
    background-color: #EFEFEF;
}

.party-selector.hide,
.theme-selector.hide {
    display: none;
}

.party-selector,
.theme-selector {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: #fff;
}

.party-selector h2,
.theme-selector h2 {
    display: block;
    margin: 0;
    padding: 12px 15px;

    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 24px;

    background: #EFEAFF;
    font-weight: normal;
    cursor: pointer;
    background-image: url(assets/close-btn.png);
    background-repeat: no-repeat;
    background-position: 94% 17px;
}

.party-selector h2 {
    background-color: #EFEFEF;
}

.party-selector ul,
.theme-selector ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.party-selector li label,
.theme-selector li a {
    display: block;
    padding: 18px 10px;
    text-decoration: none;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 24px;
}

.party-selector > ul > li,
.theme-selector > ul > li {
    cursor: pointer;
    border-bottom: 1px solid #979797;
}

.theme-selector > ul > li {
    padding-left: 5px;
}

.party-selector > ul > li .checkbox,
.theme-selector > ul > li .checkbox {
    padding: 20px 16px;
}

.party-selector > ul > li .checkbox div,
.theme-selector > ul > li .checkbox div {
    margin-right: 15px;
}

.theme-selector li.selected {
    background: #F4F4F4;
    border-bottom: 1px solid #F4F4F4;
}

.theme-selector li.selected a {
    font-weight: bold;
    padding-bottom: 0;
}

.theme-selector label {
    display: block;
    padding: 10px;
    color: #000;
}

.party-selector > button,
.theme-selector > button {
    display: block;
    width: 100%;
    background: #524779;
    padding: 15px;
    border: 0;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 24px;
}

.select-theme-message,
.select-party-message,
.select-subject-message {
    display: none;
}

.theme-and-subject-viewer {
    margin: 35px -20px 0;
}

.theme-and-subject-viewer h2 {
    display: block;
    background-color: #EFEFEF;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 19px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
}

.theme-and-subject-viewer h3 {
    padding: 10px 20px 20px;
    margin: 0;
    font-size: 19px;
    font-weight: normal;
    color: #000000;
    letter-spacing: 0;
}

.theme-and-subject-viewer h4 {
    padding: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 19px;
    color: #000000;
    letter-spacing: 0;
}

.theme-and-subject-viewer .opinion {
    padding: 20px;
    margin-bottom: 20px;
}

.theme-and-subject-viewer .opinion h4 {
    display: none;
}

.theme-and-subject-viewer .opinion img {
    display: block;
    margin: 0 auto;
    max-height: 24px;
}

.theme-and-subject-viewer .opinion p {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 24px;
}

.theme-and-subject-viewer .opinion p.one-liner {
    font-weight: bold;
}

.checkbox,
.election-checkbox {
    cursor: pointer;
    display: flex;
    user-select: none;
}

.checkbox div,
.election-checkbox div {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    border: 2px solid #000000;
    margin-right: 7px;
    flex-shrink: 0;

    overflow: hidden;
}

.checkbox span,
.election-checkbox span {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    vertical-align: top;
    word-break: break-word;
}

.checkbox div {
    background-color: #000;
}

.checkbox div img {
    width: 16px;
}

.election-checkbox-choice {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    user-select: none;
}

.election-checkbox-choice li {
    display: inline-block;
    margin-left: 20px;
}

.election-checkbox-choice li:first-of-type {
    margin-left: 0;
}

.back-to-top {
    text-decoration: none;

    float: right;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0;
    line-height: 18px;

    margin-bottom: 20px;
}

.back-to-top span {
    text-decoration: underline;
}

.social-media-icons {
    clear: both;
    margin-top: 20px;
}

.social-media-icons button {
    margin-right: 10px;
}

.sidebar .social-media-icons {
    display: none;
}

.agree-overview,
.disagree-overview {
    border: 1px solid rgba(0,108,7,0.50);
    border-radius: 4px;
    padding: 0 20px 20px;
    margin-bottom: 15px;
}

.disagree-overview {
    border-color: rgba(190,0,0,0.50);
}

.agree-disagree-overview {
    margin-top: 30px;
    margin-bottom: 30px;
}

.agree-overview h1,
.disagree-overview h1 {
    color: #006C07;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px;
}

.disagree-overview h1 {
    color: #BE0000;
}

.agree-overview ul,
.disagree-overview ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.agree-overview img,
.disagree-overview img {
    width: 32px;
}

.agree-overview li,
.disagree-overview li {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    letter-spacing: 0;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    body {
        margin: 20px auto;
        padding: 0 20px;
    }

    .party-selector.hide,
    .theme-selector.hide {
        display: unset;
    }

    .party-selector > button,
    .theme-selector > button,
    .btn-party-select,
    .btn-theme-select {
        display: none;
    }
    
    .party-selector,
    .theme-selector {
        position: unset;;
        background-color: #fff;
    }

    .party-selector h2,
    .theme-selector h2 {
        display: block;
        font-size: 19px;
        font-weight: bold;
        color: #000000;
        letter-spacing: 0;
        line-height: 28px;
        background: unset;
        cursor: unset;
        background: none;
        padding-left: 0;
    }

    .party-selector > ul,
    .theme-selector > ul {
        margin-bottom: 10px;
    }
    
    .party-selector li {
        background: #F4F4F4;
    }

    .party-selector > ul > li,
    .theme-selector > ul > li {
        border-bottom: 0;
    }

    .party-selector li label,
    .theme-selector li a {
        background: #EFEAFF;
        border-radius: 4px;
        padding: 12px 10px;
        margin-top: 4px;
    }

    .theme-selector > ul > li {
        padding-left: 0;
    }

    .theme-selector li.selected a {
        font-weight: bold;
        padding-bottom: 12px;
    }

    .theme-selector li.selected > a {
        background: #524779;
        color: #FFFFFF;
        letter-spacing: 0;
    }

    .party-selector > ul > li .checkbox,
    .theme-selector > ul > li .checkbox {
        padding: 10px 16px;
    }
    
    .party-selector > ul ul li:first-child .checkbox,
    .theme-selector > ul ul li:first-child .checkbox {
        padding-top: 20px;
    }
    
    .party-selector > ul ul li:last-child .checkbox,
    .theme-selector > ul ul li:last-child .checkbox {
        padding-bottom: 20px;
    }
    
    .select-theme-message,
    .select-party-message,
    .select-subject-message {
        display: block;
        padding: 24px;
        background: #E5F6FD;
        font-size: 19px;
        color: #000000;
        letter-spacing: 0;
        line-height: 28px;
    }
    
    .theme-and-subject-viewer {
        margin-left: 0;
        margin-right: 0;
    }

    .theme-and-subject-viewer h2 {
        font-size: 28px;
        font-weight: bold;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        line-height: 36px;
        padding: 16px;
    }

    .theme-and-subject-viewer h3 {
        padding-left: 0;
    }
        
    .theme-and-subject-viewer .opinion {
        display: flex;
        align-items: center;
    }

    .theme-and-subject-viewer .opinion img {
        flex: 0 0 100px;
        margin-right: 20px;
        max-width: 70px;
        max-height: 40px;
    }

    .theme-and-subject-viewer .opinion p {
        flex: 1 1 auto;
        margin: 0;
    }

    .agree-disagree-overview {
        display: flex;
        justify-content: space-between;
    }
    
    .agree-disagree-overview > div {
        flex: 1 1 50%;
    }
    
    .agree-disagree-overview > div:first-of-type {
        margin-right: 20px;
    }

    .two-columns {
        display: flex;
        justify-content: space-between;
    }

    .sidebar {
        flex: 1 0 230px;
        min-width: 250px;
        max-width: 290px;
    }
    
    .spacer {
        flex: 0 0 30px;
    }

    .main {
        flex: 1 1 auto;
    }

    .agree-disagree-overview {
        margin-top: 45px;
    }

    .sidebar .social-media-icons {
        display: block;
    }

    .main .social-media-icons {
        display: none;
    }
}

@media (min-width: 1024px) {
    .spacer {
        flex: 0 0 60px;
    }
}